<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="py-0 pt-5"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              src="@/assets/basketLogoDialogDistrictManagement.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-1 dialogTitle-text">Hotel Info</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="toggleListHotelInfoModal({ show: false })"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <div style="text-align: justify; font-size: 16px">
              <hr
                style="
                    height: 2px;
                    border-width: 0;'
                    color: gray;
                    background-color: gray;
                  "
              />
              {{ this.hotelData.checking_in_date_str }} |
              {{ this.hotelData.hotel_timezone }}
              <v-icon
                dark
                class="icons"
                color="#6B6B6B"
                size="22"
                @click="
                  toggleHotelInfoModal({
                    show: true,
                    type: 'edit',
                    id: hotelData.id,
                  })
                "
                v-if="this.adminAccess != 'Player'"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                size="18"
                color="red"
                @click="
                  toggleDeleteHotelModal({
                    Delete: true,
                    id: hotelData.id,
                  })
                "
                v-if="this.adminAccess != 'Player'"
                >mdi-delete</v-icon
              >
              <br />
              <br />
              <strong>Check in date: </strong
              >{{ this.hotelData.checking_in_date_str }} &nbsp;
              <strong>Check out date: </strong
              >{{ this.hotelData.checking_out_date_str }}
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
              <div v-if="this.hotelData.player_confirmations.length != 0">
                <strong>Players: </strong>
                <div
                  v-for="(monthlySlot, index) in hotelplayeritems1"
                  :key="index"
                >
                  <tr class="students">
                    <td>{{ monthlySlot.player_name }}</td>
                    &nbsp;&nbsp;
                    <td
                      style="color: green; font-weight: 600"
                      v-if="monthlySlot.confirmation_status == 'confirmed'"
                    >
                      Confirmed
                    </td>
                    <td style="color: red; font-weight: 600" v-else>
                      Not Confirmed
                    </td>
                  </tr>
                </div>
              </div>
              <div v-if="this.hotelData.player_confirmations.length == 0">
                <tr class="students">
                  <strong>Players: </strong>
                  <td
                    v-for="(item, i) in hotelplayeritems"
                    :key="i"
                    v-text="item.name"
                    class="student show"
                  ></td>
                </tr>
                <br />
              </div>
              <br />
              <strong>Category: </strong>Hotel
              <br />
              <br />
              <strong>Description: </strong> {{ this.hotelData.note }}
              <br />
              <br />
              <strong>Hotel Name: </strong> {{ this.hotelData.name }}
              <br />
              <strong>Hotel Address: </strong>
              {{ this.hotelData.hotel_address }}
              <br />
              <strong>Contact Person Name: </strong>
              {{ this.hotelData.contact_person_name }}
              <br />
              <strong>Contact Person Email: </strong>
              {{ this.hotelData.contact_email }}
              <br />
              <strong>Confirmation Number: </strong>
              {{ this.hotelData.confirmation_number }}
              <hr
                style="
                  height: 2px;
                  border-width: 0;
                  color: gray;
                  background-color: gray;
                "
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <delete-hotel-modal></delete-hotel-modal>
    <HotelInfoModal></HotelInfoModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { GAME_HOTEL_GET_API } from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "ListCalendarManagement",
  components: {
    DeleteHotelModal: () => import("../DeleteHotelModal/DeleteHotelModal.vue"),
    HotelInfoModal: () =>
      import("../../GameManagement/HotelInfoModal/HotelInfoModal"),
  },
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      loading: false,
      formLoading: false,
      hotelData: {},
      hotelplayeritems: [],
      hotelplayeritems1: [],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "calendarManagement/getShowListHotelInfo",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    layout() {
      return this.$route.meta.layout;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleListHotelInfoModal({ show: value });
      },
    },
    type() {
      return this.$store.state.calendarManagement.listHotelInfoModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Task Added";
      } else {
        return "Task Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add New Task";
        case "edit":
          return "Edit Event";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      if (value) {
        // Modal open callback
        this.openCallBackModal();
      } else {
        // Modal close callback
        this.closeCallBackModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // sponsor Modal
      toggleListHotelInfoModal: "calendarManagement/toggleListHotelInfoModal",
      toggleDeleteHotelModal: "calendarManagement/toggleDeleteHotelModal",
      toggleHotelInfoModal: "gameManagement/toggleHotelInfoModal",
      showToast: "snackBar/showToast",
    }),
    openCallBackModal() {
      console.log("open modal");
      this.getHotelDetail();
    },
    closeCallBackModal() {},

    getHotelDetail() {
      const successHandler = (res) => {
        console.log(res);
        this.loading = false;
        this.hotelData = res.data.hotel_detail;
        var hoteldisplaydatess = new Date(this.hotelData.checking_in_date);
        var datesssh = new Date(hoteldisplaydatess);
        this.hoteldisplaydatess = datesssh.toDateString();
        if (this.hotelData.player_confirmations.length != 0) {
          this.hotelplayeritems1 = this.hotelData.player_confirmations;
        }
        if (this.hotelData.player_list != null) {
          this.hotelplayeritems = this.hotelData.player_list;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["hotel_id"] =
        this.$store.state.calendarManagement.listHotelInfoModal.hotelID;
      Axios.request_GET(
        GAME_HOTEL_GET_API,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    converTime(value) {
      if (value) {
        var temp = value.split(":");
        if (parseInt(temp[0]) > 12) {
          value = parseInt(temp[0]) - 12 + ":" + temp[1] + " PM";
        } else {
          value = temp[0] + ":" + temp[1] + " AM";
        }
      }

      return value;
    },
  },
};
</script>
<style scoped>
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
</style>
